<template>
  <div class="video-item" :style="getThumbnail(props.thumbnails) ? `background-image: url(${getThumbnail(props.thumbnails)})`: ''" @click="play" :title="i18n.t('Přehrát video')" data-pageelementname="element_video" :data-pageelementspecification="typeof props.isOurChannel === 'boolean' ? (props.isOurChannel ? 'video_internal' : 'video_external') : undefined">
  </div>
</template>
<script setup>

const props = defineProps({
  title: String,
  youtubeId: String,
  thumbnails: Array,
  isOurChannel: Boolean
});

const i18n = useI18n();

const getThumbnail = (thumbnails) => {

  if (!thumbnails || thumbnails.length === 0) {
    thumbnails = [{ url: `https://i3.ytimg.com/vi/${props.youtubeId}/maxresdefault.jpg`, width: 1280 }];
  }

  const sortedThumbnails = thumbnails.sort((a, b) => b.width - a.width);
  let result = sortedThumbnails.filter(thumbnail => thumbnail.width < 1280);

  if (result.length === 0) {
    result = sortedThumbnails;
  }

  return result[0].url;
};

const play = () => useVideo().play(props.youtubeId, props.title);

</script>
<style lang="postcss">
.video-item {
  @apply flex items-center justify-center aspect-video bg-primary-400 bg-center bg-cover cursor-pointer;

  &:after {
    @apply content-[''] bg-[url('/img/play.svg')] bg-no-repeat bg-center bg-contain w-[25%] h-[25%] transition-all duration-300;

    &:hover {
      @apply scale-[1.2];
    }
  }
}
</style>